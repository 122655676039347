const Autodesk = window.Autodesk;
const THREE = window.THREE;

export default class BIMAcademyHTMLPoints extends Autodesk.Viewing.Extension {
  constructor(viewer, options = {}) {
    super(viewer, options);

    this.viewer = viewer;

    this.scene = {};
    this.labelRenderer = null;
    this.labels = [];
    this.vue = options.vue;
    this.css2dObjectLabels = [];
  }

  addLabels() {
    for (let i = 0; i < this.labels.length; i++) {
      this.css2dObjectLabels.push(
        new THREE.CSS2DObject(this.labels[i].element)
      );
      this.scene.add(this.css2dObjectLabels[i]);
    }

    return true;
  }

  positionLabels() {
    for (let i = 0; i < this.labels.length; i++) {
      const position = this.getPositionOfdbid(this.labels[i].dbid).position;
      this.css2dObjectLabels[i].position.set(
        position.x,
        position.y,
        position.z
      );
    }
  }

  load() {
    this.scene = new THREE.Scene();

    for (let i = 0; i < this.vue.$slots.popupInstance.length; i++) {
      this.labels.push({
        dbid: this.vue.$slots.popupInstance[i].componentOptions.propsData.dbid,
        index: this.vue.$slots.popupInstance[i].componentOptions.propsData
          .index,
        data: this.vue.$slots.popupInstance[i].componentOptions.propsData.data,
        element: this.vue.$slots.popupInstance[i].elm,
      });
    }

    const result = this.addLabels();

    if (!result)
      console.error("[HTMLPoints] something went wrong adding labels");

    for (let i = 0; i < this.labels.length; i++) {
      const div = this.labels[i].element;

      div.className = i;
      div.style.cursor = "pointer";
      div.style.pointerEvents = "bounding-box";

      div.onmouseenter = (e) => {
        if (e.target.className === div.className) {
          this.vue.$store.commit("viewer/setHotSpot", {
            index: e.target.className,
            state: false,
          });
        }
      };

      div.onmouseleave = (e) => {
        if (e.target.className === div.className) {
          this.vue.$store.commit("viewer/setHotSpot", {
            index: e.target.className,
            state: true,
          });
        }
      };
    }

    // this.positionLabels();

    this.viewer.addEventListener(
      Autodesk.Viewing.EXTENSION_LOADED_EVENT,
      (e) => {
        this.labelRenderer = new THREE.CSS2DRenderer(this.viewer.container);
        this.positionLabels();
        this.labelRenderer.render(this.scene, e.camera);
        //this.viewer.fitToView();
      }
    );

    this.viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, (e) => {
      if (this.labelRenderer != null) {
        this.positionLabels();
        this.labelRenderer.render(this.scene, e.camera);
      }
    });

    this.viewer.addEventListener(Autodesk.Viewing.VIEWER_RESIZE_EVENT, (e) => {
      if (this.labelRenderer != null) {
        this.positionLabels();
        this.labelRenderer.render(this.scene, e.camera);
      }
    });

    return true;
  }

  getPositionOfdbid(dbid) {
    const _viewer = this.viewer;
    const tree = _viewer.model.getInstanceTree();

    const result = {
      position: {},
    };
    tree.enumNodeFragments(
      dbid,
      function(frag) {
        const bBox = new THREE.Box3();

        const fragList = _viewer.model.getFragmentList();
        fragList.getWorldBounds(frag, bBox);

        result.position = bBox.center();
      },
      true
    );

    return result;
  }

  unload() {
    this.labelRenderer = null;
    this.labels = [];
    this.css2dObjectLabels = [];

    const removeElements = (elms) => [...elms].forEach((el) => el.remove());
    removeElements(document.querySelectorAll(".forgePopup"));

    return true;
  }
}
Autodesk.Viewing.theExtensionManager.registerExtension(
  "BIMAcademy.HTMLPoints",
  BIMAcademyHTMLPoints
);

//three.js feature from library
//notTODO : write something to call when resize event called for recalcuating position as resizing will bust positioning as it stands

THREE.CSS2DObject = function(element) {
  THREE.Object3D.call(this);

  this.element = element;
  this.element.style.position = "absolute";

  this.addEventListener("removed", function() {
    if (this.element.parentNode !== null) {
      this.element.parentNode.removeChild(this.element);
    }
  });
};

THREE.CSS2DObject.prototype = Object.create(THREE.Object3D.prototype);
THREE.CSS2DObject.prototype.constructor = THREE.CSS2DObject;

THREE.CSS2DRenderer = function(div) {
  //console.log("THREE.CSS2DRenderer", THREE.REVISION);

  let _width, _height;
  let _widthHalf, _heightHalf;

  const vector = new THREE.Vector3();
  const viewMatrix = new THREE.Matrix4();
  const viewProjectionMatrix = new THREE.Matrix4();

  const domElement = document.createElement("div");
  domElement.style.overflow = "hidden";
  domElement.style.pointerEvents = "none";
  domElement.style.position = "absolute";
  domElement.style.top = "0px";
  domElement.style.left = "0px";

  domElement.className = "labels";
  div.appendChild(domElement);
  this.domElement = domElement;

  this.getSize = function() {
    return { width: _width, height: _height };
  };

  this.setSize = function(width, height) {
    _width = width;
    _height = height;

    _widthHalf = width / 2;
    _heightHalf = height / 2;

    domElement.style.width = width + "px";
    domElement.style.height = height + "px";
  };

  this.setSize(div.clientWidth, div.clientHeight);

  const renderObject = function(object, camera) {
    if (object instanceof THREE.CSS2DObject) {
      vector.setFromMatrixPosition(object.matrixWorld); //Revit working version

      vector.applyMatrix4(viewProjectionMatrix);

      const element = object.element;
      const style =
        "translate(-50%,-50%) translate(" +
        (vector.x * _widthHalf + _widthHalf) +
        "px," +
        (-vector.y * _heightHalf + _heightHalf) +
        "px)";

      element.style.transform = style;

      if (element.parentNode !== domElement) {
        domElement.appendChild(element);
      }
    }

    for (let i = 0, l = object.children.length; i < l; i++) {
      renderObject(object.children[i], camera);
    }
  };
  this.render = function(scene, camera) {
    if (camera != null) {
      scene.updateMatrixWorld();

      if (camera.parent === null) {
        camera.updateMatrixWorld();
      }

      viewMatrix.copy(camera.matrixWorldInverse);
      // console.log("-----");
      // console.log(camera);
      // console.log(viewMatrix);
      // console.log("-----");
      viewProjectionMatrix.multiplyMatrices(
        camera.projectionMatrix,
        viewMatrix
      );

      renderObject(scene, camera);
    }
  };
};
